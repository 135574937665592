import request from '@/utils/request'

/**
 * 财务模块
 */

//收款账户类api

//账户认证列表
export const selectList = (data)=> request('/admin/auth/routing/select',data);
//账户认证记录列表
export const routingRecord = (data)=> request('/admin/auth/routing/record',data);
//个人会员实名认证
export const certification = (data)=> request('/admin/auth/routing/certification',data);
//企业会员实名认证
export const companyCertification = (data)=> request('/admin/auth/routing/company/certification',data);
//绑定卡
export const bindBankCard = (data)=> request('/admin/auth/routing/bindBankCard',data);
//获取手机验证码
export const routingSend = (data)=> request('/admin/auth/routing/send',data);
//电子会员签约
export const signContract = (data)=> request('/admin/auth/routing/signContract',data);
//平台转账
export const applicationTransfer = (data)=> request('/admin/auth/routing/applicationTransfer',data);
//提现
export const withdrawApply = (data)=> request('/admin/auth/routing/withdrawApply',data);
//解绑
export const routingDelete = (data)=> request('/admin/auth/routing/delete',data);
//查询详情
export const getDetail = (data)=> request('/admin/auth/routing/getDetail',data);
//交易记录
export const transactionList = (data)=> request('/admin/order/transaction/list',data);
//交易记录导出
export const transactionListExport = (data)=> request('/admin/order/transaction/export',data);


/**
 * 供应商报表**/

//查询供应商报表
export const getQueryReportList = (data) => request('/admin/routing/queryReportList', data);
//导出供应商报表
export const exportReportList = (data) => request('/admin/routing/exportReportList', data);
//查询供应商分账月份List
export const getQueryRoutingMonthList= (data) => request('/admin/routing/queryRoutingMonthList', data, 'get');
//查询供应商订单明细List
export const getQueryOrderDetailList = (data) => request('/admin/routing/queryOrderDetailList', data);
//导出供应商订单明细
export const exportOrderDetailList = (data) => request('/admin/routing/exportOrderDetailList', data);
//查询供应商商品销售明细
export const getQueryGoodsReportList = (data) => request('/admin/routing/queryGoodsReportList', data);
//导出供应商商品销售明细
export const exportGoodsReportList = (data) => request('/admin/routing/exportGoodsReportList', data);


/**
 * 分账账单 **/

//查询分账账单
export const getQueryRoutingBillList = (data) => request('/admin/routing/queryRoutingBillList', data);
//修改分账账单
export const editAmendRoutingBill = (data) => request('/admin/routing/amendRoutingBill', data);
//确认分账
export const saveAffirmRouting = (data) => request('/admin/routing/affirmRouting', data);
//查询银行账号余额
export const getQueryBalance = (data) => request('/admin/auth/routing/queryBalance', data);
