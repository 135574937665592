<template>
	<div>
		<div class="main-content">
			<div class="filter-container" style="padding-top: 15px;">
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">订单号:</label>
					<el-input v-model="searchKey" style="margin-left:5px;width: 300px;" placeholder="请填写订单号" clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">商品名称:</label>
					<el-input v-model="searchKey2" style="margin-left:5px;width: 300px;" placeholder="请填写商品名称" clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">商品编码:</label>
					<el-input v-model="goodsCode" style="margin-left:5px;width: 300px;" placeholder="请填写商品编码" clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label" >供货商名称:</label>
					<el-select v-model="supplier" filterable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in supplierOptions" :key="item.id" :label="item.supplierName" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label" >审核状态:</label>
					<el-select v-model="grade" filterable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in gradeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">商品分组:</label>
					<el-select v-model="group" filterable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in groupOptions" :key="item.groupId" :label="item.groupName" :value="item.groupId"></el-option>
					</el-select>
				</div>
				<div class="filter-item" style="margin-left: 20px;">
					<label class="label">下单时间:</label>
					<el-date-picker v-model="finishTimes" type="datetimerange" :picker-options="pickerOptions" range-separator="～"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
					<button-permissions :datas="'exchangeRecordExport'">
						<el-button type="primary" style="margin-left:30px" @click="exchangeRecordExport" v-loading="exportLoading">导出</el-button>
					</button-permissions>
				</div>
			</div>
			<!-- table -->
			<div class="table-container">
				<el-table :data="tableData" style="width: 100%" v-loading="loading">
					<el-table-column key="1" prop="createTime" label="下单时间" width="200px"></el-table-column>
					<el-table-column key="2" prop="goodsName" label="商品名称" width="300px"></el-table-column>
					<el-table-column key="3" prop="orderNo" label="订单编号" width="250px"></el-table-column>
					<el-table-column key="4" prop="transactionId" label="支付单号" width="300px"></el-table-column>
					<el-table-column key="5" prop="goodsAmount" label="金额(元)" width="130px"></el-table-column>
					<el-table-column key="6" prop="payTypeValue" label="收款方式" width="170px"></el-table-column>
					<el-table-column key="7" prop="orderStatusValue" label="订单状态" width="170px"></el-table-column>
					<el-table-column key="8" label="操作" width="200px">
						<template slot-scope="scope">
							<!-- <button-permissions :datas="'lookDetail'"> -->
								<el-button type="text" @click="toDetail(scope.row)">查看详情</el-button>
							<!-- </button-permissions> -->
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
					<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
					layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	import config from '@/config/index';
	import {
		transactionList,
		transactionListExport,
	} from '@/api/finance.js'
	import {
		productGroupListData,
		supplierGetList
	} from "@/api/goods.js";
	import utils from '@/utils/util.js';
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				searchKey: '',
				searchKey2: '',
				goodsCode:'',
				supplierName:'',
				grade: null,
				gradeOptions: [{
						id: 0,
						name: '待付款'
					},
					{
						id: 1,
						name: '待发货'
					},
					{
						id: 2,
						name: '待收货'
					},
					{
						id: 3,
						name: '待评价'
					},
					{
						id: 4,
						name: '已完成'
					},
					{
						id: 5,
						name: '已关闭'
					},
					{
						id: 6,
						name: '退款中'
					},
				],
				group: null,
				groupOptions: [],
				supplier:null,
				supplierOptions:[],
				authorizedStartTime: '',
				authorizedEndTime: '',
				tableData: [],
				page: {
					total: 0,
					size: 20,
					current: 1
				},
				exportLoading: false,
				loading: false,
				imgUrl: config.IMG_BASE,
				defaultHeader: config.DEFAULT_HEADER,
				finishTimes: '',
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			};
		},
		beforeMount() {
			this.getSupplierList();
			this.getGroupList();
			this.getDataList();
		},
		methods: {
			//获取供货商列表
			async getSupplierList(){
				try {
					let data = {
						pageNo: 1,
						pageSize: 10000,
						supplierName: ''
					};
					let res = await supplierGetList(data);
					if (res.success) {
						this.supplierOptions = res.data.records;
					}
				} catch (e) {
					//TODO handle the exception
					console.log(e);
				}
			},
			// 获取分组列表数据
			async getGroupList () {
				try {
					let data = {
						pageNo: 0, // 略过数据
						pageSize: 10000, // 取的数据
					}
					let result = await productGroupListData(data);
					this.groupOptions = result.data.records || [];
			
				} catch (e) {
					console.log(e)
				} 
			
			},
			async getDataList() {
				try {
					if (this.finishTimes) {
						this.authorizedStartTime = this.finishTimes[0];
						this.authorizedEndTime = this.finishTimes[1];
					} else {
						this.authorizedStartTime = '';
						this.authorizedEndTime = '';
					}
					this.loading = true
					let data = {
						goodsName: this.searchKey2,
						startTime: this.authorizedStartTime,
						endTime: this.authorizedEndTime,
						pageNo: this.page.current,
						pageSize: this.page.size,
						orderNo: this.searchKey,
						orderStatus: this.grade == null ? this.grade : this.grade + 1,
						goodsCode:this.goodsCode,
						groupId:this.group,
						supplierId:this.supplier
					}
					let result = await transactionList(data);
					if (result.success) {
						this.loading = false;
						//1 待付款 2 待发货 3 待收货 4 待评价 5 已完成 6 已关闭 7:退款中
						result.data.records.map(item => {
							item.orderStatusValue = this.getStateVal(item.orderStatus);
							item.payTypeValue = this.getPayStateVal(item.payType);
							return item;
						})
						this.tableData = result.data.records;
						this.page.total = result.data.total;
					} else {
						this.loading = false;
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						})
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				}
			},
			getStateVal(val) {
				var str = "";
				//1 待付款 2 待发货 3 待收货 4 待评价 5 已完成 6 已关闭 7:退款中
				switch (val) {
					case 1:
						str = '待付款';
						break;
					case 2:
						str = '待发货';
						break;
					case 3:
						str = '待收货';
						break;
					case 4:
						str = '待评价';
						break;
					case 5:
						str = '已完成';
						break;
					case 6:
						str = '已关闭';
						break;
					case 7:
						str = '退款中';
						break;
					default:
						str = '';
						break;
				}
				return str;
			},
			getPayStateVal(val) {
				var str = "";
				//1 微信 2 通联支付 3 通商云 
				switch (val) {
					case 1:
						str = '微信';
						break;
					case 2:
						str = '通联支付';
						break;
					case 3:
						str = '通商云';
						break;
					default:
						str = '';
						break;
				}
				return str;
			},
			handleFilter() {
				this.page.current = 1
				this.getDataList()
			},
			handleSizeChange(val) {
				this.page.size = val;
				this.getDataList();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page.current = val;
				this.getDataList();
			},
			toDetail(row) {
				let params = {
					Id: row.orderId ? row.orderId : 0
				}
				this.publicToDetails(params, '/order/orderDetailpage', this);
			},
			async exchangeRecordExport() {
				try {
					if (this.finishTimes) {
						this.authorizedStartTime = this.finishTimes[0];
						this.authorizedEndTime = this.finishTimes[1];
					} else {
						this.authorizedStartTime = '';
						this.authorizedEndTime = '';
					}
					this.exportLoading = true
					let data = {
						isDownload: true, //下载文件标记
						goodsName: this.searchKey2,
						startTime: this.authorizedStartTime,
						endTime: this.authorizedEndTime,
						pageNo: this.page.current,
						pageSize: this.page.size,
						orderNo: this.searchKey,
						goodsCode:this.goodsCode,
						orderStatus: this.grade == null ? this.grade : this.grade + 1,
						groupId:this.group,
						supplierId:this.supplier
					}
					let result = await transactionListExport(data);
					utils.convertRes2Blob(result);
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.exportLoading = false;
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	.top {
		background: #fff;
		padding: 20px;

		.title {
			font-size: 14px;
		}

		.dis-item {
			// cursor: pointer;
			margin-top: 20px;
			margin-left: 10px;
			font-size: 14px;
			margin-right: 50px;
		}

		.dis-content {
			display: flex;
			flex-direction: row;
		}

		.dis-num {
			color: #999;
			font-size: 12px;
			line-height: 20px;
		}
	}

	.main-content {
		margin-top: 15px;
		background: #fff;
		padding: 0 10px;
	}

	.key-options {
		/deep/.el-input__inner {
			width: 130px !important;
		}
	}

	.edit-grade {
		/deep/.el-input {
			width: 350px !important;
		}
	}

	.authorize-content {
		line-height: 20px;

		.message {
			margin-left: 5px;
		}
	}

	.direct-recommend-content {
		display: flex;
		flex-direction: row;

		/deep/.el-input {
			width: 260px;
		}
	}

	.referrer-card {
		width: 260px;
		height: 60px;
		border: 1px solid #67c23a;
		margin-top: 10px;
		border-radius: 1px;
		position: relative;
		padding: 5px;
		display: flex;
		flex-direction: row;
		line-height: 20px;
		align-items: center;

		.check {
			position: absolute;
			right: -1px;
			top: -4px;
		}

		/deep/.el-checkbox {
			width: 14px;
			height: 14px;
		}

		/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #67c23a;
			border-color: #67c23a;
		}

		/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
			border-color: #67c23a;
		}

		/deep/.el-checkbox__inner:hover {
			border-color: #67c23a;
		}

		.referrer-img {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			object-fit: cover;
			margin: 0 10px 0 10px;
		}

		.referrer-text {
			display: inline-block;
			width: 165px;
			overflow: hidden;
		}

		.name {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 14px;
			color: #909399;
		}
	}

	.oneFlows {
		width: 100px;
		margin-right: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

	}

	.over-two {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
